// From https://github.com/LorDOniX/json-viewer/blob/master/src/json-viewer.css
.json-viewer {
    color: #000;
    padding-left: 20px;

    ul {
        list-style-type: none;
        margin: 0;
        margin: 0 0 0 1px;
        border-left: 1px dotted #CCC;
        padding-left: 2em;
    }

    .hide {
        display: none;
    }

    .type-string {
        color: #0B7500;
    }

    .type-date {
        color: #CB7500;
    }

    .type-boolean {
        color: #1A01CC;
        font-weight: bold;
    }

    .type-number {
        color: #1A01CC;
    }

    .type-null,
    .type-undefined {
        color: #90A;
    }

    a.list-link {
        color: #000;
        text-decoration: none;
        position: relative;


        &::before {
            color: #AAA;
            content: '\25BC';
            position: absolute;
            display: inline-block;
            width: 1em;
            left: -1em;
        }

        &.collapsed:before {
            content: '\25B6';
        }

        &.empty:before {
            content: '';
        }
    }

    .items-ph {
        color: #AAA;
        padding: 0 1em;

        &:hover {
            text-decoration: underline;
        }
    }
}


.jsoneditor {
    .jsoneditor-search {
        input {
            --finder-background-color: #FFF;
        }
    }
}
