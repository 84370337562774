body.live-theme {
    --finder-anchor-focus-text-decoration: none;

    .finder-layout {
        position: relative;

        .finder-header {
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
        }

        .finder-content {
            finder-system-error {
                .container {
                    padding-top: 80px;
                    margin-bottom: 50px;
                }
            }
        }
    }
}
