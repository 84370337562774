@keyframes slide-up {
    from { transform: translateY(0%); }
    to { transform: translateY(-100%); }
}

@keyframes slide-up-boomerang {
    0% { transform: translateY(0%); }
    50% { transform: translateY(-100%) }
    100% { transform: translateY(0%); }
}

@keyframes slide-block-lr {
    0% { transform: translateX(0%); }
    20% { transform: translateX(80%); }
    50% { transform: translateX(90%) }
    100% { transform: translateX(0%); }
}

@keyframes pop-up {
    from { bottom: calc(var(--animation-height) * -1); }
    to { bottom: -1px; }
}

@keyframes pop-in-right {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-90%); }
}

@keyframes pop-in-left {
    0% { transform: translateX(0%); }
    100% { transform: translateX(90%); }
}

@keyframes pop-in-bottom {
    0% { transform: translateY(100%); }
    100% { transform: translateY(var(--animation-pop-in-bottom-end, 10%)); }
}

@keyframes simple-sprite {
    0% { transform: translateY(0%); }
    100% { transform: translateY(-100%); }
}
