// Styling for the terms and conditions pages
body.generic-terms-conditions,
body.copy-only-no-images {
    --generic-text-block-max-width: 900px;
    --multi-column-text-max-width: 900px;
    --collapsible-text-max-width: 900px;

    .campaign-header {
        .header-text {
            .title {
                @include type36;
            }

            .subtitle {
                @include type30;
            }
        }
    }

    .lg-spacer {
        margin-bottom: 0;
    }

    .generic-text-block {
        .generic-text.dynamic-html {
            margin-bottom: 0;
        }
    }

    .campaign-content {
        .block {
            margin-bottom: 20px;

            .header {
                .title {
                    @include type24;
                    font-weight: 700;
                }

                .subtitle {
                    @include type20;
                }
            }
        }
    }

    div.dynamic-html,
    div.dynamic-html p,
    div.dynamic-html ul,
    div.dynamic-html ul li,
    div.dynamic-html ol,
    div.dynamic-html ol li {
        @include type16;
        margin-bottom: 20px;
    }

    div.dynamic-html ul li,
    div.dynamic-html ol li {
        margin-bottom: 0;
    }

    div.dynamic-html p,
    div.dynamic-html ul,
    div.dynamic-html ol {
        margin-top: 0;
    }
}

body.standard-custom {
    --generic-text-margin-bottom: 0;

    .campaign-content {
        .descriptive-text {
            max-width: 900px;
            margin: 0 auto;
        }
    }

    .lg-spacer {
        margin-bottom: 30px;
    }

    .finder-footer {
        .dlr.horizontal-separator {
            margin-top: 50px;
        }
    }
}

body.news-standard {
    .generic-text-block {
        .dynamic-html.generic-text {
            margin-bottom: 0;
        }
    }
}
