@mixin hoverZoom($imageClass) {
    #{$imageClass} {
        transition: transform 800ms;
    }

    &:hover,
    &:focus {
        #{$imageClass} {
            transform-style: preserve-3d;
            transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
        }
    }
}

// From https://stackoverflow.com/a/66626895/189937
// convert string to number
@function to-number($value) {
    @if type-of($value) == 'number' {
        @return $value;
    }

    @else if type-of($value) != 'string' {
        @error 'Value for `to-number` should be a number or a string.';
    }

    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1) == '-';
    $numbers: (
        '0': 0,
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9
    );

    @for $i from if($minus, 2, 1) through str-length($value) {
        $character: str-slice($value, $i, $i);

        @if (index(map-keys($numbers), $character) or $character == '.') {
            @if $character == '.' {
                $digits: 1;
            }

            @else if $digits == 0 {
                $result: $result * 10 + map-get($numbers, $character);
            }

            @else {
                $digits: $digits * 10;
                $result: $result + map-get($numbers, $character) / $digits;
            }
        }
    }

    @return if($minus, -$result, $result);
}

@function decimal-round($number, $digits: 0, $mode: round) {
    $n: 1;

    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }

    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    }

    @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }

    @if $digits >0 {
        @for $i from 1 through $digits {
            $n: $n * 10;
        }
    }

    @if $mode == round {
        @return calc(round($number * $n) / $n);
    }

    @else if $mode == ceil {
        @return ceil($number * $n) / $n;
    }

    @else if $mode == floor {
        @return floor($number * $n) / $n;
    }

    @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}

@function rgba-to-hex($rgba, $base: 255) {
    $colorCode: ('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F');

    // 255 / 100 = 2.55
    // 10 / 16 = 0.625

    // Need this for base alpha color (default is white)
    $base: decimal-round(calc($base / 100), 2);

    $alpha: alpha($rgba);

    // ============================================= RED ================================
    $redStr: '' + calc(red($rgba) / 16);

    $index: str-index($redStr, '.');

    // add decimal number incase it does not have and update index
    @if $index == null {
        $redStr: $redStr + '.0';
        $index: str-index($redStr, '.');
    }

    $redInteger : to-number(str-slice($redStr, 0, $index - 1));
    $redDecimal: decimal-round(calc(to-number(str-slice($redStr, $index + 1, $index + 1)) / 0.625));


    // ============================================= GREEN ============================
    $greenStr: '' + calc(green($rgba) / 16);


    $index: str-index($greenStr, '.');

    // add decimal number incase it does not have and
    @if $index == null {
        $greenStr: $greenStr + '.0';
        $index: str-index($greenStr, '.');
    }

    $greenInteger : to-number(str-slice($greenStr, 0, $index - 1));
    $greenDecimal: decimal-round(calc(to-number(str-slice($greenStr, $index + 1, $index + 1)) / 0.625));

    // ============================================= BLUE ============================
    $blueStr: '' + calc(blue($rgba) / 16);

    $index: str-index($blueStr, '.');

    // add decimal number incase it does not have and
    @if $index == null {
        $blueStr: $blueStr + '.0';
        $index: str-index($blueStr, '.');
    }

    $blueInteger : to-number(str-slice($blueStr, 0, $index - 1));
    $blueDecimal: decimal-round(calc(to-number(str-slice($blueStr, $index + 1, $index + 1)) / 0.625));

    // ============================================= ALPHA ============================
    $alphaStr: '' + decimal-round(calc((($alpha * 100) * $base) / 16));

    $index: str-index($alphaStr, '.');

    @if $index == null {
        $alphaStr: $alphaStr + '.0';
        $index: str-index($alphaStr, '.');
    }

    $alphaInteger : '' + calc(to-number(str-slice($alphaStr, 0, $index - 1)));

    $index: str-index($alphaInteger, '.');

    @if $index == null {
        $alphaInteger: $alphaInteger + '.0';
        $index: str-index($alphaInteger, '.');
    }

    $alphaInteger : calc(to-number(str-slice($alphaStr, 0, $index - 1)));

    $alphaDecimal: calc(to-number(str-slice('' + calc(to-number(str-slice($alphaStr, $index + 1, str-length($alphaStr))) / 0.625), 0, 2)));

    @return unquote('#' +
        nth($colorCode, $redInteger + 1) +
        nth($colorCode, $redDecimal + 1) +
        nth($colorCode, $greenInteger + 1) +
        nth($colorCode, $greenDecimal + 1) +
        nth($colorCode, $blueInteger + 1) +
        nth($colorCode, $blueDecimal + 1) +
        nth($colorCode, $alphaInteger + 1) +
        nth($colorCode, $alphaDecimal + 1));
}

@function hex-to-rgb($hex) {
    @return unquote('#{red($hex)} #{green($hex)} #{blue($hex)}');
}

@mixin get-anchor-offset($height, $margin-top) {
    content: "";
    display: block;
    height: #{$height};
    margin-top: #{$margin-top};
    visibility: hidden;
}
