body {
    @include type-base;
    @include type16;
    color: var(--finder-primary-active);
}

a,
a:hover,
a:focus {
    color: var(--finder-primary-action);
    cursor: pointer;
}

a {
    text-decoration: var(--anchor-text-decoration, none);
    font-weight: var(--anchor-font-weight, initial);
}

a:hover,
a:focus {
    text-decoration: var(--finder-anchor-focus-text-decoration);
}

a:focus:not(:active) {
    --focus-mixin-color: var(--finder-primary-action);
    @include focus;
}

.anchor:focus:not(:active) {
    --focus-mixin-color: var(--finder-primary-action);
    @include focus;
}

hr {
    height: 0;
    border: 0;
    border-bottom: 1px solid var(--finder-hr-color, --finder-detail-grey);
    margin: 0;
    padding: 0;
}

hr.gradient {
    @include horizontal-gradient;
}

[hidden] {
    display: none !important;
}

[unresolved] {
    display: none !important;
}

input,
textarea,
select {
    background-color: var(--finder-background-color);
    border-color: var(--finder-chrome-grey);
    color: var(--finder-primary-color);
}
