.type-base {
    @include type-base;
}

.type50 {
    @include type50;
}

.type36 {
    @include type36;
}

.type28 {
    @include type28;
}

.type24 {
    @include type24;
}

.type18 {
    @include type18;
}

.type16 {
    @include type16;
}

.type14 {
    @include type14;
}

.type12 {
    @include type12;
}

.heavy {
    @include heavy;
}

.black {
    @include black;
}

.oblique {
    @include oblique;
}

h1, .header1 {
    @include type28;
    @include black;
}

h2, .header2 {
    @include type24;
    @include black;
}

h3, .header3 {
    @include type24;
    @include black;
}

h4, .header4 {
    @include type18;
    @include black;
}

h5, .header5 {
    @include type16;
    @include black;
}

body, .body1 {
    @include type16;
}

.body2,
.body3,
.body4 {
    @include type14;
}

.icon-font {
    @include icon-font;
}

/* Tablet Size Viewport */

@include tablet {
    h1, .header1 {
        @include type36;
        @include black;
    }
    h2, .header2 {
        @include type28;
        @include black;
    }
    h3, .header3 {
        @include type28;
        @include black;
    }
    h4, .header4 {
        @include type20;
        @include black;
    }
    h5, .header5 {
        @include type18;
        @include heavy;
    }
    body, .body1 {
        @include type16;
    }
    .body2 {
        @include type14;
    }
}

/* Desktop Size Viewport */

@include desktop {
    h1, .header1 {
        @include type50;
        @include black;
    }
    h2, .header2 {
        @include type36;
        @include black;
    }
    h3, .header3 {
        @include type28;
        @include black;
    }
    h4, .header4 {
        @include type24;
        @include black;
    }
    h5, .header5 {
        @include type20;
        @include black;
    }
    body, .body1 {
        @include type18;
    }
    .body2 {
        @include type16;
    }
    .body3 {
        @include type14;
    }
}
