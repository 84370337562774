// [PRO-355171] White lines showing in safari when the media engine is full screen
.wdwFooter {
    .horizontalSeparator {
        &.fullWidth {
            position: inherit;
        }
    }
}

// [GIT-5381] - Polymer is injecting a font-family style to the span's tags,
// making the icons to dissapear in the syndicated header & footer
.finder-header,
.finder-footer {
    .gnbCategory .icon {
        font-family: pepmdx;
    }
}
