/* You can add global styles to this file, and also import other style files */
@import './mixins/typography';
@import './mixins/scaffolding';
@import './mixins/helpers';
@import './mixins/default-colors';
@import './reset';
@import './scaffolding';
@import './typography';
@import './dynamic-html';
@import './generic-text';
@import './custom-urls';
@import './json-viewer';
@import './images';
@import './global-nav';
@import './media-engine';
@import './vars';
@import './debug';
@import './component-shared';
@import './overrides';
@import './squirt';
@import './animation-keyframes';

body {
    --desktop-large-max-width: 1300px;
    --desktop-medium-max-width: 1100px;

    --list-container-max-width-desktop-medium: 1100px;
    --list-container-max-width-desktop-large: 1300px;
    --search-container-max-width-desktop-medium: 1100px;
    --search-container-max-width-desktop-large: 1300px;
    --container-max-width-desktop-medium: 1100px;
    --container-max-width-desktop-large: 1300px;

    // RA components use these. Should be able to remove once RA's compoents are updated to defaul to InspireTWDC
    --font-family-base-theme: var(--finder-theme-primary-font);
    --font-family-light-theme: var(--finder-theme-primary-font);
    --font-family-heavy-theme: var(--finder-theme-primary-font);
    --font-family-black-theme: var(--finder-theme-primary-font);
    --font-family-medium-theme: var(--finder-theme-primary-font);
    --font-family-roman-theme: var(--finder-theme-primary-font);
    --font-family-book-theme: var(--finder-theme-primary-font);

    @include defaultColors;

    padding: 0;
    margin: 0;
    @include type-base();
    -webkit-text-size-adjust: 100%;

    // Gets applied to the body when the mobile nav is opened.
    &.mobile-nav-open {
        width: 100%;
    }

    & app-root ~ img {
        display: none;
    }

    /*& app-root ~ iframe {
        display: none;
    }*/
}

sup {
    font-size: 75%;

    // zero line-height won't interfere with the positioning that follows
    line-height: 0;

    // Where the magic happens: makes all browsers position the sup properly relative to the surrounding text
    position: relative;
    vertical-align: baseline;

    /* Move the superscripted text up */
    top: -0.4em;
}

.mobile-nav-open {
    .aag {
        .media {
            pointer-events: none;
        }
    }
}

.section-subtitle :first-child {
    @include type16;
    @include heavy;
}

.details-container {
    .middle-section {
        .map-container {
            &.expanded {
                finder-detail-map {
                    .container {
                        .title-container {
                            .map-title {
                                color: var(--finder-primary-color);
                                @include type28;
                            }
                        }
                    }
                }
            }
        }
    }
}

.campaign .disclaimer p {
    margin-bottom: 5px;
}

.list-block {
    --list-block-text-link-color: var(--finder-theme-bg-color);
    --list-block-text-link-decoration: underline;

    .list-element-subtitle {
        h3 {
            font-size: 21px;
            font-weight: 500;
        }
    }

    .list-element-text {
        p {
            font-weight: 300;
            line-height: 1.5;
        }

        a {
            color: var(--list-block-text-link-color);
            text-decoration: var(--list-block-text-link-decoration);
        }
    }
}

.scroll-tracker {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    overflow-y: scroll;

    & > div {
        position: static;
    }
}

.alert-box {
    position: fixed;
    top: 20px;
    left: calc(50% - 150px);
    width: 300px;
    padding: 20px;
    z-index: 99999;
    border: 1px solid var(--finder-chrome-grey);
    background-color: var(--finder-background-color);
    box-shadow: 5px 5px 5px var(--finder-primary-color);
    text-align: center;
    cursor: pointer;

    .alert-x {
        position: absolute;
        top: 0;
        right: 8px;
        font-size: 12px;
        font-style: normal;
        color: #A00;
    }
}

.campaign-email {
    --ct-email-capture-anchor-color-no-bg: var(--finder-theme-action-color);
    --ct-email-capture-link-decoration-no-bg: none;
    --ct-email-capture-link-color: var(--finder-theme-bg-color);
    --ct-email-capture-link-decoration: underline;
    --ct-email-capture-anchor-border-color-no-bg: var(--finder-theme-primary-color);

    &:not(.no-bg) { // For the email capture
        a {
            color: var(--ct-email-capture-link-color, #FFF);
            text-decoration: var(--ct-email-capture-link-decoration, underline);
        }
    }

    &.no-bg { // For the email capture with no background
        a {
            color: var(--ct-email-capture-anchor-color-no-bg);
            text-decoration: var(--ct-email-capture-link-decoration-no-bg, none);

            &:focus {
                border: 1px dotted var(--ct-email-capture-anchor-border-color-no-bg);
            }
        }
    }
}

// Hide global messaging when CT hero video in full screen
.finder-campaign.fullscreen .global-messaging-old {
    display: none;
}

// apply to body to keep Firefox from horizontal scrolling
.no-horizontal-scroll {
    overflow-x: hidden;
}

// align open/close norgies for mobile sub-nav content
finder-mobile-content-navigation {
    finder-font-icon {
        .svg.size-s {
            margin-bottom: -4px;
        }
    }
}

/** CSS suppression - Small World interim shim approach */
// LACD/LATAM suppression classes
body.es-ar .suppress-latam,
body.es-cl .suppress-latam,
body.es-co .suppress-latam,
body.es-mx .suppress-latam,
body.es-pe .suppress-latam,
body.pt-br .suppress-latam,

// GDPR/U.K. and E.U. suppression classes
body.en-gb .suppress-uk-eu,
body.en-eu .suppress-uk-eu,

// FR. suppression classes
body.fr-ca .suppress-fr-ca {
    display: none;
}

// TODO - Move into proper partial once merge of PR #952 is complete
body.generic-terms-conditions,
body.copy-only-no-images {
    div.dynamic-html {
        h1.pageTitle {
            @include type36;
        }

        h1,
        h2,
        h3,
        h4 {
            margin-bottom: 20px;
        }

        table {
            th,
            td,
            caption {
                padding: 5px;
            }
        }

        ul,
        ol {
            li {
                margin-bottom: 20px;
            }
        }
    }

    .block {
        finder-horizontal-rule {
            hr {
                margin: 50px auto;
                max-width: var(--generic-text-block-max-width);
            }
        }
    }
}

// Visually hide element but the element can be detected by screen readers
// used for element with aria-live to announce message
.visually-hidden {
    @include screen-reader-text;
}

// Form error message for EVE
.form-error-message {
    @include type12;
    color: #DD3700;
    display: inline-flex;
    gap: 5px;

    finder-font-icon {
        --font-icon-svg-height: 13px;
        --font-icon-svg-width: 13px;
        --font-icon-svg-background-color: #DD3700;
    }

    &.hidden {
        display: none;
    }
}


/* Fairy Godmother PEP-style survey modal... see https://myjira.disney.com/browse/GIT-44682 */
.LPMcontainer .animated {
    animation-duration: 1s;
    animation-fill-mode: both
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

.LPMcontainer .fadeInUp {
    animation-name: fadeInUp
}

.ui-dialog.ui-widget {
    --hero-cta-action-hover-color: var(--finder-theme-bg-color);
    --hero-cta-action-hover-decoration: underline;
    --hero-cta-action-focus-outline-color: var(--finder-theme-bg-color);

    padding: 25px;

    #modalTmpContent {
        @include type16;
        line-height: 18px;
        color: var(--finder-primary-color);
    }

    background-color: #FFF;
    border-radius: 17px;

    .accessibleText,
    .ui-dialog-titlebar-close {
        @include screen-reader-text
    }
    .modalUIPlusButtons {
        text-align: center;
        .gradient {
            border: 1px solid var(--finder-primary-color);
            padding: 5px 15px;
            min-width: 150px;
            margin: 10px;

            &:hover {
                text-decoration: var(--hero-cta-action-hover-decoration);
            }
            &:focus {
                color: var(--hero-cta-action-hover-color);
                outline: 1px dotted var(--hero-cta-action-focus-outline-color);
            }
        }

        .buttonText {
            font-weight: bold;
        }
    }
}

// Hide the extra HR in the footer of the DCL search page
body.dcl {
    .horizontal-separator {
        display: none;
    }
}
