@mixin defaultColors() {
    & {
        --finder-message-grey: #78899E;
        --finder-inactive-grey: #A1AFC0;
        --finder-detail-grey: #D0D7DE;
        --finder-chrome-grey: #ECF1F4;
        --finder-good-to-go-green: #4EB86C;
        --finder-alert-orange: #FF4605;
    }
}
