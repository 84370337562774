.size-label {
    font-size: 18px;
    font-weight: 800;
    color: #000;
    background-color: #FFF;
    padding: 10px;
    opacity: 1;
}

.squirt-overlay {
    position: absolute;
    inset: 0;
    z-index: 2;
    opacity: 0.3;
    animation: fadeOut .5s;

    &:hover {
        opacity: 1;
        animation: fadeIn .5s;
    }

    &.squirt-tiny {
        background-color: #008000;
        border: 5px solid #008000;
    }
    &.squirt-small {
        background-color: #0000FF;
        border: 5px solid #0000FF;
    }
    &.squirt-medium {
        background-color: #FF0;
        border: 5px solid #FF0;
    }
    &.squirt-big {
        background-color: #FFA500;
        border: 5px solid #FFA500;
    }
    &.squirt-huge {
        background-color: #F00;
        border: 5px solid #F00;
    }
}

#squirt-results {
    background-image: url('^assets/images/squirt/squirt-bg.jpeg');
    background-size: cover;
    background-repeat: no-repeat;

    .avatar-wrapper {
        animation: spin 15s ease-in-out infinite;
        display: var(--squirt-avatar-display, unset);
    }

    .avatar.squirt {
        transform: translateY(20px);
        animation: float 3s ease-in-out infinite;
        display: block;
    }
}

.noaction {
    cursor: wait;
    pointer-events: none;

    .squirt-processing {
        position: fixed;
        inset: 0;
        background-color: #000;
        opacity: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        color: #FFF;
        text-shadow: 0 0 5px #000;
        z-index: 2;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@keyframes float {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@keyframes spin {
    0%,
    93.5% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
