@import './mixins/helpers';
:host {
    --finder-promo-link: var(--finder-theme-bg-color);
    --finder-promo-link-hover: var(--finder-theme-bg-color);
    --border-between-boxes-color: var(--finder-border-color);
}

// [START] Availability Calendar
.avail-container {
    // Update the styling for the tooltip.
    finder-tooltip {
        --finder-background-color: #DBDBDB;
        --finder-primary-active: #000;
    }

    // Change the styling for the drop downs so they're side by side
    .selectors {
        finder-dropdown {
            .dropdown-picker-wrapper {
                width: initial;

                .dropdown-button {
                    width: 100%;
                }
            }
        }
    }

    // This will normalize the cell after we add content to it
    .calendar-cell {
        height: 46px;
        width: 14%;
    }

    a {
        text-decoration: none;
    }

    .calendars {
        .calendar {
            height: 420px;
            position: relative;

            &::after {
                @include notched-corner(30px, 40px, var(--availability-calendar-notch-background), var(--availability-calendar-border-color), 2px);
                bottom: -16px;
                right: -12px;
            }

            .calendar-header {
                display: none !important;
            }

            .calendar-content {
                --finder-detail-grey: var(--availability-calendar-border-color);
                border-style: solid;
                border-width: 2px !important; // I have to override the default
                background-color: var(--availability-calendar-background-color);

                .calendar-cell {
                    --finder-message-grey: var(--finder-primary-color);
                }
                .calendar-table {
                    // This was added to fix a weird height issue that only showed up in firefox
                    height: max-content !important;

                    .calendar-row {
                        .calendar-cell {
                            // Make sure the tooltip displays over the days of the adjacent calendar
                            z-index: inherit !important;
                        }
                        // Make sure the days calendar days don't display over the tooltip
                        &.weekdays {
                            .calendar-cell {
                                z-index: 0 !important;
                            }
                        }
                    }
                }
            }
        }

        tbody {
            display: table-footer-group;

            // This is how the space between the days and the numbers is created
            &::before {
                height: 30px !important;
            }
        }
        .month-name {
            display: none;
        }
    }

    .available-departure,
    .travel-day,
    .not-available,
    .available-selected {
        height: 44px;
        width: 44px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 1px;
        @include font18;
        font-weight: 700;
    }

    .available-departure,
    .travel-day {
        background-color: var(--availability-calendar-day-background);
    }

    .available-departure,
    .available-selected,
    .travel-day {
        color: var(--finder-primary-color);
    }

    .available-selected {
        background-color: var(--availability-calendar-selected-day-background);
        border: 1px solid var(--availability-calendar-selected-day-border);
        box-sizing: border-box;

        // This is a square, rotated at and angle and cliped to just the bottom and a quarter of the inside
        &::after {
            content: ' ';
            position: absolute;
            width: 15px;
            height: 20px;
            transform: rotate(45deg);
            bottom: 34px;
            left: 37px;
            background-color: var(--availability-calendar-selected-day-notch-background);
            border-bottom: 1px solid var(--availability-calendar-selected-day-border);
            clip-path: polygon(50% 59%, -5% 100%, 105% 100%);
        }
    }

    .available-departure,
    .available-selected {
        // This is the line under the number
        &::before {
            content: ' ';
            position: absolute;
            border-bottom: 2px solid var(--availability-calendar-number-underline);
            width: 30px;
            bottom: 5px;
        }
    }

    .limited-availability {
        border: 1px solid var(--availability-calendar-limited-availability-border-color);
        box-sizing: border-box;
        overflow: hidden;
        // Change the underline color
        &::before {
            border-bottom-color: var(--availability-calendar-limited-availability-number-underline);
        }

        // This is the notch in the corner
        &::after {
            content: ' ';
            position: absolute;
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
            right: -15px;
            top: -10px;
            background-color: var(--availability-calendar-limited-availability-notch-background);
        }
    }

    // Change the color of the underline when it's selected
    a .available-selected {
        &::before {
            border-bottom: 2px solid var(--availability-calendar-selected-number-underline);
        }
    }

    // Make sure non travel days don't have a line under the number
    .available-selected.travel-day:not(.available-departure) {
        &::before {
            border-bottom: none;
        }
    }

    .not-available {
        border: 1px solid var(--availability-calendar-not-available-border-color);
        color: var(--availability-calendar-not-available-color);
        background-color: var(--availability-calendar-not-available-background-color);
        box-sizing: border-box;
        // This is the top right angled line
        &::after {
            content: ' ';
            position: absolute;
            border-bottom: 1px solid var(--availability-calendar-not-available-slash);
            width: 15px;
            transform:rotate(-45deg);
            top: 4px;
            right: -3px;
        }
        // This is the bottom left angled line
        &::before {
            content: ' ';
            position: absolute;
            border-bottom: 1px solid var(--availability-calendar-not-available-slash);
            width: 15px;
            transform: rotate(-45deg);
            left: -2px;
            bottom: 5px;
        }
    }
}

@include desktop {
    .avail-container {
        .calendars {
            .calendar {
                .calendar-header {
                    display: inherit !important;
                }
            }

            .month-name {
                display: inherit;
                text-transform: uppercase;
            }
        }
    }
}
// [END] Availability Calendar

// these anchor styles are here to catch and style links being returned from the
// service which were not caught when the styling was applied in the finder-promo SCSS
// the color definition for each pseudo-state is necesary to override the values
// set in _scaffolding.scss
finder-promo {
    a {
        color: var(--finder-promo-link);
        text-decoration: var(--finder-anchor-focus-text-decoration);

        &:hover {
            color: var(--finder-promo-link-hover);
            text-decoration: none;
        }
        &:focus,
        &:focus:not(:active) {
            color: var(--finder-promo-link-hover);
            outline: 1px dotted var(--finder-theme-bg-color);
        }
    }
}

finder-button-card {
    .click-zone {
        @include hoverZoom('.picture-img');
    }
}

finder-guests-also-viewed {
    .gav-link {
        @include hoverZoom('.picture-img');
    }
}

finder-content-header {
    .content-header {
        .subtitle {
            h2 {
                @include roman;
            }
        }
    }
}

finder-component-header {
    hr {
        border: 0;
        margin: 1.3em 0;
        border-bottom: 1px solid var(--border-between-boxes-color);
    }
    .title,
    .subtitle {
        img {
            vertical-align: middle;
        }
    }
}

block-layout-renderer {
    .shared-table {
        .block-element {
            --block-layout-renderer-lg-spacer-margin-bottom: 20px;
        }
    }
}

// Specific styling for the Flip Clock.
finder-flip-clock finder-countdown-flip-clock {
    .flip-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    // Hours, Minutes and Seconds Wrapper
    .tick-group {
        display: flex;
        flex-basis: 33%;
        align-items: center;
        flex-direction: column;

        .tick-flip-panel {
            color: var(--flip-clock-panel-color);
            border-radius: 4px;
            background-color: var(--flip-clock-panel-bg-color);
        }

        // Digits container
        .tick-flip {
            width: var(--flip-clock-days-panel-width, 30px);
            height: var(--flip-clock-days-panel-height, 48px);
        }

        // Clock numbers
        .tick-flip-panel-text-wrapper {
            display: flex;
            font-size: var(--flip-clock-days-panel-font-size, 35px);
            font-weight: 600;
            justify-content: center;
            text-shadow: 0 2px 2px rgb(56, 36, 40, 0.4);
        }

        // Clock Labels
        .tick-label {
            font-size: 9px;
            font-weight: 600;
            margin: 3px 0 6px 0;
            text-transform: uppercase;
            color: var(--flip-clock-tick-label-color);
            text-shadow: var(--flip-clock-tick-label-text-shadow);
        }

        // Days Wrapper
        &:first-of-type {
            flex-basis: 100%;

            .tick-flip-panel {
                background-color: var(--flip-clock-days-panel-bg-color);

                &.tick-flip-panel-front {
                    border-bottom: var(--flip-flock-days-panel-border-bottom, none);
                }
            }

            .tick-flip {
                width: var(--flip-clock-days-panel-width, 38px);
                height: var(--flip-clock-days-panel-height, 58px);
            }

            .tick-flip-panel-text-wrapper {
                font-size: var(--flip-clock-days-panel-font-size, 45px);
            }

            .tick-label {
                font-size: 12px;
            }
        }

        // Easter Eggs
        .easter-egg {
            width: 26px;
            height: 30px;
            position: absolute;

            &.top {
                bottom: -15px;
            }

            &.bottom {
                z-index: 2;
                top: calc( 50% - 15px);
            }

            // Days Easter Eggs
            &.parks {
                width: 40px;
                height: 40px;
                &.bottom {
                    top: calc( 50% - 23px);
                }
                // MK Easter Egg
                &.mk {
                    height: 50px;
                    &.bottom {
                        top: calc( 50% - 32px);
                    }
                }
            }
        }
    }

    // The way the flip clock works is:
    // When you change the font-size -> then the containers scale.
    // Since we need to have control over the size of the panels,
    // the font doesn't scale, so I had to use media queries to set the proper size.
    @include mobile {
        .tick-group {
            .tick-flip {
                width: var(--flip-clock-days-panel-width, 35px);
                height: var(--flip-clock-days-panel-height, 54px);
            }

            .tick-flip-panel-text-wrapper {
                font-size: var(--flip-clock-days-panel-font-size, 38px);
            }

            &:first-of-type {
                .tick-flip {
                    width: var(--flip-clock-days-panel-width, 40px);
                    height: var(--flip-clock-days-panel-height, 70px);
                }

                .tick-flip-panel-text-wrapper {
                    font-size: var(--flip-clock-days-panel-font-size, 48px);
                }
            }
        }
    }

    @include tablet {
        .tick-group {
            .tick-flip-panel {
                background-color: var(--flip-clock-desktop-panel-bg-color);
            }

            .tick-flip {
                width: var(--flip-clock-days-panel-width, 38px);
                height: var(--flip-clock-days-panel-height, 28px);
            }

            .tick-flip-panel-text-wrapper {
                font-size: var(--flip-clock-days-panel-font-size, 29px);
            }

            &:first-of-type {
                .tick-flip-panel {
                    background-color: var(--flip-clock-desktop-days-panel-bg-color);
                }

                .tick-flip {
                    width: var(--flip-clock-days-panel-width, 36px);
                    height: var(--flip-clock-days-panel-height, 52px);
                }

                .tick-flip-panel-text-wrapper {
                    font-size: var(--flip-clock-days-panel-font-size, 42px);
                }
            }
        }
    }

    @include desktop-small {
        .tick-group {
            .tick-flip {
                width: var(--flip-clock-days-panel-width, 32px);
                height: var(--flip-clock-days-panel-height, 54px);
            }

            .tick-flip-panel-text-wrapper {
                font-size: var(--flip-clock-days-panel-font-size, 38px);
            }

            &:first-of-type {
                .tick-flip {
                    width: var(--flip-clock-days-panel-width, 45px);
                    height: var(--flip-clock-days-panel-height, 80px);
                }

                .tick-label {
                    font-size: 14px;
                }

                .tick-flip-panel-text-wrapper {
                    font-size: var(--flip-clock-days-panel-font-size, 56px);
                }
            }
        }
    }

    @include desktop-medium {
        .tick-group {
            .tick-label {
                font-size: 12px;
            }

            &:first-of-type {
                .tick-label {
                    margin: 5px 0 8px 0;
                }
            }
        }
    }
}

finder-syndicated-header {
    body.minimal-syndicated & {
        min-height: 42px;

        .syndicated-navigation,
        .syndicated-container {
            min-height: 42px;
        }

        .syndicated-navigation__chrome__disney,
        .syndicated-navigation__chrome__search,
        .syndicated-navigation__chrome__links div:not(.syndicated-language-selector),
        .syndicated-navigation__chrome__links>div:not(:last-child):after,

        // mobile
        .syndicated-navigation__nav__sections div:not(.syndicated-language-selector),
        .syndicated-navigation__nav__toggle--search {
            display: none;
        }

        // this rule stops the link from being clickable
        .syndicated-navigation__nav__logo {
            pointer-events: none;
        }

        @media (min-width: 975px) {
            .syndicated-navigation__nav {
                margin-top: -30px;
            }
        }
    }
}

finder-syndicated-footer {
    body.minimal-syndicated & {
        .syndicated-footer {
            display: none; // hides footer links except legal links
        }
    }
}

finder-campaign-cta {
    --cta-content-anchor-color: var(--finder-theme-primary-color);

    .cta-content {
        .cta-text {
            a {
                color: var(--cta-content-anchor-color);
                text-decoration: var(--cta-content-anchor-text-decoration, none);

                &:focus {
                    text-decoration: var(--cta-content-anchor-focus-text-decoration, none);
                    outline: var(--cta-content-anchor-focus-outline, 1px dotted);
                }

                &:hover {
                    text-decoration: var(--cta-content-anchor-hover-text-decoration, none);
                }
            }
        }
    }
}

// Multiple Items Per Row Directive
.row-layout {
    --tutorial-item-margin: 0;
    --tutorial-item-media-width: auto;
    --media-gallery-width: auto;

    --gap-space-2: calc(var(--row-layout-gap) / 2);
    --gap-space-3: calc(var(--row-layout-gap) * 2 / 3);
    --gap-space-4: calc(var(--row-layout-gap) * 3 / 4);

    --row-max-2: calc(50% - var(--gap-space-2));
    --row-max-3: calc(33% - var(--gap-space-3));
    --row-max-4: calc(25% - var(--gap-space-4));

    display: flex;
    flex-flow: row wrap;
    box-sizing: border-box;
    gap: var(--row-layout-gap);
    justify-content: var(--row-layout-justify-content, center);

    &.row-max-2 {
        --tutorial-item-max-width: var(--row-max-2);
    }

    &.row-max-3 {
        --tutorial-item-max-width: var(--row-max-3);

        // This may seem a little backwards - when there are tablet columns
        // the default is the minus 1 item layout, but when we are in
        // desktop it has all the items
        &.has-tablet-columns {
            --tutorial-item-max-width: var(--row-max-2);
        }
    }

    &.row-max-4 {
        --tutorial-item-max-width: var(--row-max-4);

        // This may seem a little backwards - when there are tablet columns
        // the default is the minus 1 item layout, but when we are in
        // desktop it has all the items
        &.has-tablet-columns {
            --tutorial-item-max-width: var(--row-max-3);
        }
    }

    .row-item {
        flex-basis: var(--tutorial-item-max-width);
    }
}

.header-mobile-left {
    text-align: left;
}
.header-mobile-right {
    text-align: right;
}
.header-mobile-center {
    text-align: center;
}

@media (min-width: 768px) {
    .header-desktop-left {
        text-align: left;
    }

    .header-desktop-right {
        text-align: right;
    }

    .header-desktop-center {
        text-align: center;
    }
}

// Component header alignment
// These selectors target the combination of the mobile class along with any other class
// that starts with ' desktop-'. Since the alignment classes are not the very first class
// they will all start with the whitespace - this just ensures we target them correctly.
.mobile-left[class*=' desktop-'] {
    text-align: left;
}

.mobile-right[class*=' desktop-']  {
    text-align: right;
}

.mobile-center[class*=' desktop-']  {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

@include tablet {
    // These selectors target the combination of the desktop class along with any other class
    // that starts with ' mobile-'. Since the alignment classes are not the very first class
    // they will all start with the whitespace - this just ensures we target them correctly.
    .desktop-left[class*=' mobile-']  {
        text-align: left;
    }

    .desktop-right[class*=' mobile-']  {
        text-align: right;
    }

    .desktop-center[class*=' mobile-']  {
        text-align: center;
    }
}
// Component header alignment

@media (min-width: 1024px) {
    .row-layout {
        &.row-max-3 {
            &.has-tablet-columns {
                --tutorial-item-max-width: var(--row-max-3);
            }
        }

        &.row-max-4 {
            &.has-tablet-columns {
                --tutorial-item-max-width: var(--row-max-4);
            }
        }
    }
}
// Multiple Items Per Row Directive
