@mixin type-base() {
    font-family: var(--finder-theme-primary-font), Arial, sans-serif;
    /* Inspire is supposed to support tabular numbers, but adding it causes
     * terrible font spacing everywhere. Leaving commented out while investigating
     */
    // font-variant-numeric: tabular-nums;
}

@mixin icon-font() {
    font-family: "pepmdx";
}

@mixin font50() {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: .5px;
}

@mixin font40() {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: .5px;
}

@mixin font36() {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: .5px;
}

@mixin font30() {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: .5px;
}

@mixin font28() {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: .5px;
}

@mixin font24() {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: .2px;
}

@mixin font20() {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: .2px;
}

@mixin font18() {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: .2px;
}

@mixin font16() {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .25px;
}

@mixin font14() {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .25px;
}

@mixin font12() {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .25px;
}

@mixin type50() {
    @include font50;
    font-weight: 100;
}

@mixin type40() {
    @include font40;
    font-weight: 100;
}

@mixin type36() {
    @include font36;
    font-weight: 100;
}

@mixin type30() {
    @include font30;
    font-weight: 800;
}

@mixin type28() {
    @include font28;
    font-weight: 100;
}

@mixin type24() {
    @include font24;
    font-weight: 100;
}

@mixin type20() {
    @include font20;
    font-weight: 100;
}

@mixin type18() {
    @include font18;
    font-weight: 400;
}

@mixin type16() {
    @include font16;
    font-weight: 400;
}

@mixin type14() {
    @include font14;
    font-weight: 400;
}

@mixin type12() {
    @include font12;
    font-weight: 400;
}

@mixin light() {
    font-weight: 100;
}

@mixin roman() {
    font-weight: 400;
}

@mixin heavy() {
    font-weight: 700;
}

@mixin black() {
    font-weight: 900;
}

@mixin oblique() {
    font-style: italic;
}
