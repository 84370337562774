.dynamic-html {
    --dynamic-html-anchor-color: var(--finder-primary-action);
    --dynamic-html-anchor-focus-outline: 1px dotted var(--finder-message-grey);
    --dynamic-html-anchor-focus-text-decoration: var(--finder-anchor-focus-text-decoration);
    --dynamic-html-anchor-hover-outline: 1px dotted var(--finder-message-grey);
    --dynamic-html-anchor-hover-text-decoration: var(--finder-anchor-focus-text-decoration);
    --package-card-anchor-color: var(--finder-theme-action-color);
    --package-card-list-border-color: var(--finder-border-color);
    --page-msg-anchor-text-decoration: none;

    p,
    ul,
    ol {
        @include type16;

        @include tablet {
            @include type18;
        }
    }

    a {
        cursor: pointer;
        text-decoration: var(--dynamic-html-anchor-text-decoration, none);
        color: var(--dynamic-html-anchor-color);

        &:hover {
            color: var(--dynamic-html-anchor-hover-color, var(--dynamic-html-anchor-color));
            outline: var(--dynamic-html-anchor-hover-outline);
            text-decoration: var(--dynamic-html-anchor-hover-text-decoration);
            text-decoration-thickness: var(--dynamic-html-anchor-hover-text-decoration-thickness, 0)
        }

        &:focus {
            color: var(--dynamic-html-anchor-focus-color, var(--dynamic-html-anchor-color));
            outline: var(--dynamic-html-anchor-focus-outline);
            text-decoration: var(--dynamic-html-anchor-focus-text-decoration);
            text-decoration-thickness: var(--dynamic-html-anchor-focus-text-decoration-thickness, 0)
        }
    }

    p:not(:last-child) {
        margin-bottom: 20px;
    }

    ol,
    ul {
        margin-bottom: 20px;
        margin-left: var(--dynamic-html-list-margin-left, 20px);
    }

    li {
        display: list-item;
    }

    ol li {
        list-style-type: decimal;
        margin-left: 10px;
    }

    ol ol li {
        list-style-type: lower-alpha;
    }

    ul li {
        list-style-type: var(--dynamic-html-list-type, disc);
    }

    ul ul li {
        list-style-type: var(--dynamic-html-list-nested-type, circle);
    }

    &.package-card {
        a {
            color: var(--package-card-anchor-color, var(--finder-primary-action));
        }

        p {
            font-size: var(--package-card-paragraph-font-size, 14px);
            line-height: var(--package-card-paragraph-line-height, 20px);
            font-weight: var(--package-card-paragraph-font-weight, 400);
            font-family: var(--package-card-paragraph-font-family, inherit);
        }

        ul {
            @include type14;
            margin: 10px 0 0 0;

            li:not(:last-child) {
                border-bottom: 1px solid var(--package-card-list-border-color, var(--finder-detail-grey));
            }

            li {
                padding: var(--package-card-li-padding, 10px 10px 10px 0);
                margin-left: 16px;
            }
        }
    }

    table,
    th,
    td {
        border-spacing: 0;
        border-collapse: collapse;
        font-size: 18px;
    }

    table {
        width: 100%;
    }

    th {
        font-weight: bold;
    }

    .disclaimer {
        @include font12;
        color: var(--disclaimer-color);

        @include tablet {
            @include font14;
        }
    }

    .finderDetailTable,
    .finder-detail-table,
    .finder-detail-table-alt {
        border: 1px solid var(--ct-table-border-color, #DFDFDF);
        border-collapse: var(--ct-table-border-collapse, collapse);
        margin: var(--ct-table-margin, 0 0 20px 0);

        thead {
            th,
            td {
                background-color: var(--ct-table-thead-cell-bg-color, inherit);
                border: 1px solid var(--ct-table-border-color, #DFDFDF);
                padding: var(--ct-table-thead-cell-padding, 5px);
                color: var(--ct-table-thead-color, inherit);

                &:first-of-type {
                    border-top-left-radius: var(--ct-table-top-border-radius, unset);
                }

                &:last-of-type {
                    border-top-right-radius: var(--ct-table-top-border-radius, unset);
                }
            }
        }

        tbody {
            td {
                border: 1px solid var(--ct-table-border-color, #DFDFDF);
                padding: var(--ct-table-cell-padding, 5px);
                color: var(--ct-table-cell-color, inherit);
            }

            tr:last-of-type {
                td {
                    &:first-of-type {
                        border-bottom-left-radius: var(--ct-table-bottom-border-radius, unset);
                    }

                    &:last-of-type {
                        border-bottom-right-radius: var(--ct-table-bottom-border-radius, unset);
                    }
                }
            }
        }
    }

    .finderDetailTable,
    .finder-detail-table-alt {
        tbody {
            tr:nth-child(even) {
                background: var(--ct-table-row-even-bg-color, #DFDFDF);
            }

            tr:nth-child(odd) {
                background: var(--ct-table-row-odd-bg-color, inherit);
            }
        }
    }

    &.warn a {
        color: inherit;
        text-decoration: underline;
    }
}

/* HKDL has legal requirements on their section and subsection formatting.
 * The required values are built into the Dscribe response and differ by page so
 * a single format defined in CSS will not work.
 * See https://myjira.disney.com/browse/GIT-41981 for details */
.hkdl.generic-terms-conditions .dynamic-html {
    ol li {
        list-style-type: unset;
    }

    ol ol li {
        list-style-type: unset;
    }

    ol ol ol li {
        list-style-type: unset;
    }

    ol ol ol ol li {
        list-style-type: unset;
    }

    ol ol ol ol ol li {
        list-style-type: unset;
    }

    ul li {
        list-style-type: disc;
    }

    ul ul li {
        list-style-type: circle;
    }
}

div {
    &.dynamic-html{
        @include type16;

        @include tablet {
            @include type18;
        }
    }
}

.bullet-container {
    &.dynamic-html {
        p {
            display: inline-block;
        }
    }
}

.page-msg-container {
    .msg-wrapper {
        .msg-inner {
            .dynamic-html {
                a {
                    --page-msg-anchor-color: var(--finder-anchor-color);
                    color: var(--page-msg-anchor-color, var(--finder-alert-orange));
                    text-decoration: var(--page-msg-anchor-text-decoration, underline);

                    &:hover {
                        text-decoration: var(--finder-anchor-focus-text-decoration, none);
                    }
                }
            }
        }
    }
}

/* style generic text blocks classed as "disclaimer" */
.disclaimer {
    .generic-text-block {
        .dynamic-html, // necessary to style initial content that has p tags stripped
        .dynamic-html p {
            @include font12;

            @include tablet {
                @include font14;
            }
        }
    }
}
