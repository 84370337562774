.dynamic-html.generic-text {
    color: var(--generic-text-block-color);

    ul,
    ol {
        padding-left: var(--dynamic-generic-text-list-padding-left, 20px);

    }

    &.center {
        text-align: center;
    }

    // These selectors target the combination of the mobile class along with any other class
    // that contains 'desktop-'. These classes DO start for generic text, so we cannot use
    // whitespace like we have in other components. We need to be careful with class names here.
    &.mobile-left[class*='desktop-'] {
        text-align: left;
    }

    &.mobile-right[class*='desktop-']  {
        text-align: right;
    }

    &.mobile-center[class*='desktop-']  {
        text-align: center;
    }

    a {
        color: var(--dynamic-generic-text-anchor-color, var(--dynamic-html-anchor-color));
        text-decoration: var(--dynamic-generic-text-anchor-decoration, var(--dynamic-html-anchor-text-decoration, none));

        &:hover {
            outline: var(--dynamic-generic-text-anchor-hover-outline, var(--dynamic-html-anchor-hover-outline));
            text-decoration: var(--dynamic-generic-text-anchor-hover-decoration, var(--dynamic-html-anchor-hover-text-decoration));
            color: var(--dynamic-generic-text-anchor-hover-color, var(--dynamic-html-anchor-hover-color, inherit));
            text-decoration-thickness: var(--dynamic-generic-text-anchor-hover-text-decoration-thickness, 0);
        }

        &:focus {
            outline: var(--dynamic-generic-text-anchor-focus-outline, var(--dynamic-html-anchor-focus-outline));
            text-decoration: var(--dynamic-generic-text-anchor-focus-decoration, var(--dynamic-html-anchor-focus-text-decoration));
            color: var(--dynamic-generic-text-anchor-focus-color, var(--dynamic-html-anchor-focus-color, inherit));
            text-decoration-thickness: var(--dynamic-generic-text-anchor-focus-text-decoration-thickness, 0);
        }
    }

    li {
        margin-bottom: var(--dynamic-generic-text-list-margin-bottom, 0);
    }

    hr {
        margin: 1.3em 0;
        border: 0;
        border-bottom: 1px solid var(--border-between-boxes-color);
    }

    @include tablet {
        // These selectors target the combination of the desktop class along with any other class
        // that contains 'mobile-'. These classes DO start for generic text, so we cannot use
        // whitespace like we have in other components. We need to be careful with class names here.
        &.desktop-left[class*='mobile-']  {
            text-align: left;
        }

        &.desktop-right[class*='mobile-']  {
            text-align: right;
        }

        &.desktop-center[class*='mobile-']  {
            text-align: center;
        }
    }
}
