.debug-container {
    h3 {
        border-bottom: solid 1px var(--debug-config-border-color);
        margin-bottom: 20px;
    }

    h4 {
        margin-bottom: 10px;
    }

    .section {
        padding: 0 0 30px;

        pre {
            line-height: 20px;
            font-size: 14px;
            padding: 20px;
            background: var(--debug-env-vars-bg-color);
            border-radius: 2px;
            overflow-x: auto;
        }
    }

    finder-loading-spinner {
        display: block;
        font-size: 5px;
        width: 31px;
        margin: 25px auto;
    }
}

.freeze-background {
    overflow: hidden;
}

.modal-wrapper {
    position: fixed;
    border: 2px solid var(--finder-border-color);
    background-color: var(--finder-theme-bg-color);
    padding: 10px;
    z-index: 5;
    left: 5px;
    top: 5px;
}
